import React, { useEffect } from "react"
import { Text } from "@chakra-ui/core"
import firebase from "gatsby-plugin-firebase"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { H1 } from "../components/Typography"

export default function AboutUs() {
  useEffect(() => {
    firebase.analytics().setCurrentScreen("about_us")
  }, [])
  return (
    <Layout p={[4, 6]}>
      <SEO title="About Us" />
      <H1 textAlign="center">Welcome To Laudium Today</H1>
      <Text fontSize="xl" mt={20} textAlign="justify">
        Laudium Today is a social media network that publishes news, business
        listings, information, community events, classifieds, and advertising.
        Originally founded as Laudium BBM Service, the avant-garde in the social
        media network. First with information, crime, crime alerts,
        announcements, traffic alerts, business directory, and instant
        advertising. We are far reaching over 15000 contacts instantaneously on
        BBM, Facebook, WhatsApp, Instagram, and Twitter. Whether you looking for
        a product or service, not sure what’s happening in the area this
        weekend, concerned about the commotion in the next street. Get all this
        and more with Laudium Today KEEPING LAUDIUM CONNECTED SINCE 2012…
      </Text>
    </Layout>
  )
}
